.candidates-container .candidates-right {
  padding: 1.5rem 0.9rem;
  width: 100%;
}

.candidates-container .candidates-right h3 {
  font-size: 24px;
  color: rgba(36, 82, 147, 1);
}

.candidates-container .candidates-right .payment-board {
  background-color: #fff;
  border-radius: 20px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.candidates-container .candidates-right .payment-board .payment-board-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .tab-payment {
  display: flex;
  align-items: center;
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .tab-payment
  .each-tab-payment {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid rgb(216, 216, 216);
  font-size: 16px;
  font-weight: 600;
  color: rgba(128, 128, 128, 1);
  cursor: pointer;
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .tab-payment
  .active-border {
  border-bottom: 2px solid rgba(36, 82, 147, 1);
  color: rgba(36, 82, 147, 1);
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .tab-payment
  .each-tab-payment:hover {
  color: rgba(36, 82, 147, 1);
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .search-box {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  background: #f5f7fa;
  height: 40px;
  font-size: 18px;
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .search-box
  input {
  border: none;
  outline: none;
  background-color: transparent;
  color: #bbc2cf;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 200px;
}

.candidates-container
  .candidates-right
  .payment-board
  .payment-board-header
  .search-box
  input::placeholder {
  color: #bbc2cf;
}

.candidates-container .candidates-right .payment-board .table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
}

.candidates-container .candidates-right .payment-board .table-container .table {
  width: 100%;
  border: 1px solid #ddd;
}

.candidates-container
  .candidates-right
  .payment-board
  .table-container
  .status {
  display: flex;
  align-items: center;
}

.candidates-container .candidates-right .payment-board .table-container .pend {
  color: #f5a656;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgba(245, 166, 86, 0.15);
  padding: 5px 9px;
  width: fit-content;
}

.candidates-container .candidates-right .payment-board .table-container .succ {
  color: green;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 24px;
  background: rgb(222, 250, 222);
  padding: 5px 9px;
  width: fit-content;
}

.candidates-container
  .candidates-right
  .payment-board
  .table-container
  .table
  th,
.table td {
  padding: 10px 10px 10px 16px;
  border: 1px solid transparent;
  text-align: left;
}

.candidates-container
  .candidates-right
  .payment-board
  .table-container
  .table
  th {
  background-color: #f2f2f2;
  color: #333;
}

.candidates-container
  .candidates-right
  .payment-board
  .table-container
  .last-dot-bg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidates-container
  .candidates-right
  .payment-board
  .table-container
  .last-dot-bg
  .menu-icon {
  cursor: pointer;
}

.dialog-bg {
  padding: 1.5rem 0;
}

.dialog-bg .header-dialog {
  padding: 0 1.2rem 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdddd;
}

.dialog-bg .header-dialog h6 {
  font-size: 20px;
  font-weight: 700;
}

.dialog-bg .header-dialog .cancel {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.dialog-bg .section-two-part {
  padding: 2rem 1.2rem 1rem;
}

.dialog-bg .section-two-part .profile-part {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dialog-bg .section-two-part .profile-part img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.dialog-bg .section-two-part .profile-part .profile-name {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.dialog-bg .section-two-part .profile-part h6 {
  font-size: 20px;
  font-weight: 700;
  color: rgba(28, 37, 51, 1);
  line-height: 24.2px;
}

.dialog-bg .section-two-part .profile-part span {
  font-size: 12px;
  font-weight: 600;
  color: rgb(1, 1, 139);
  background-color: rgba(238, 247, 255, 1);
  padding: 5px 10px;
  border-radius: 15px;
}

.dialog-bg .section-two-part .profile-part p {
  margin-top: 7px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(128, 128, 128, 1);
}

.dialog-bg .section-two-part .first-box {
  border: 1px solid #dfdddd;
  border-radius: 10px;
  padding: 0 1rem;
  margin: 1.5rem 0;
  display: flex;
}

.dialog-bg .section-two-part .first-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.dialog-bg .section-two-part .first-box h4 {
  color: rgba(28, 37, 51, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  margin-top: 5px;
}

.dialog-bg .section-two-part .first-box span {
  font-size: 14px;
  font-weight: 600;
  color: rgba(54, 166, 149, 1);
  background-color: rgba(227, 255, 242, 1);
  padding: 10px 15px;
  border-radius: 25px;
  width: fit-content;
}

.dialog-bg .section-two-part .first-box .inner-one {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-bg .section-two-part .first-box .border-right-one {
  border-left: 1px solid #dfdddd;
  padding-left: 1rem;
}

.dialog-bg .section-two-part .second-part {
  margin: 1rem 0;
}

.dialog-bg .section-two-part .second-part h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(28, 37, 51, 1);
}

.dialog-bg .section-two-part .second-box {
  border: 1px solid #dfdddd;
  border-radius: 10px;
  padding: 0.3rem 1rem;
  margin: 0.7rem 0;
}

.dialog-bg .section-two-part .second-box .item-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
}

.dialog-bg .section-two-part .second-box .item-col .copy-acc {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dialog-bg .section-two-part .second-box .border-below {
  border-bottom: 1px solid #dfdddd;
}

.dialog-bg .section-two-part .second-box .item-col p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: rgba(128, 128, 128, 1);
}

.dialog-bg .section-two-part .second-box .item-col h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: rgba(28, 37, 51, 1);
}

.dialog-bg .btn-bg-proceed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-bg .btn-bg-proceed .btn-proceed-pay {
  width: 270px;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: #245293;
  color: #fff;
  cursor: pointer;
  height: 45px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
}
