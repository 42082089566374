
.dashboard-Drawer-container a {
  text-decoration: none;
  color: inherit;
}

.dashboard-Drawer-container .profile-left {
  overflow: hidden;
  position: fixed;
  height: 100vh;
  background-color: #fff;
  width: 20%;
  padding: 1.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-Drawer-container .profile-left .mobile {
  display: none;
}

.dashboard-Drawer-container .profile-left .logo-img {
  width: flex;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
}

.dashboard-Drawer-container .profile-left .logo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashboard-Drawer-container .home-box-one-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.8rem 0;
}

.dashboard-Drawer-container .home-box-one-button button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background-color: #245293;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  padding: 13px 22px;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  border: none;
}

.dashboard-Drawer-container .profile-left .profile-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.dashboard-Drawer-container .profile-left .active-tab-bg {
  background-color: #f4faff;
  color: #245293;
}

.dashboard-Drawer-container .profile-left .profile-button .main-item {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dashboard-Drawer-container .profile-left .collapse-bg {
  background-color: rgb(248, 247, 247);
}

.dashboard-Drawer-container .profile-left .profile-active {
  background-color: #f4faff;
  color: #245293;
}

.dashboard-Drawer-container .profile-left .profile-dom {
  color: #808080;
}

.dashboard-Drawer-container .profile-left .profile-dom-inner {
  color: #808080;
  background-color: red;
}

.dashboard-Drawer-container .profile-left .profile-dom:hover {
  background-color: #f4faff;
}

.dashboard-Drawer-container .profile-left .profile-button span {
  font-size: 16px;
  line-height: 19px;
}

.dashboard-Drawer-container .profile-left .dashboard-options {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-Drawer-container .profile-left .dashboard-options .box-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard-Drawer-container .profile-left .box-2 {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-Drawer-container .profile-left .box-2 .first {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard-Drawer-container .profile-left .box-2 .profile-pics {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #245293;
}

.dashboard-Drawer-container .profile-left .box-2 .box-2-text span {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
}

.dashboard-Drawer-container .profile-left .box-2 .box-2-text h5 {
  color: #5372ea;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

@media only screen and (max-width: 1300px) {
  .dashboard-Drawer-container .home-box-one-button button {
    line-height: 15px;
    padding: 10px 20px;
  }

  .dashboard-Drawer-container .profile-left .dashboard-options .box-1 {
    gap: 7px;
  }

  .dashboard-Drawer-container .profile-left .profile-button {
    padding: 0.4rem 1rem;
    font-size: 14px;
    line-height: 16px;
  }

  .dashboard-Drawer-container .profile-left .box-2 .box-2-text span {
    font-size: 14px;
    line-height: 16px;
  }

  .dashboard-Drawer-container .profile-left .box-2 .box-2-text h5 {
    font-size: 11px;
    line-height: 14px;
  }
}

@media only screen and (max-width: 1000px) {
  .dashboard-Drawer-container {
    position: relative;
  }

  .dashboard-Drawer-container .profile-left {
    position: fixed;
    top: 80px;
    right: 0;
    height: auto;
    background-color: #fff;
    width: 30%;
    padding: 0 15px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    gap: 20px;
  }

  .dashboard-Drawer-container .profile-left .desktop {
    display: none;
  }

  .dashboard-Drawer-container .profile-left .mobile {
    display: block;
  }

  .dashboard-Drawer-container .profile-left .profile-button {
    padding: 0.9rem 1rem;
  }

  .dashboard-Drawer-container .profile-left .dashboard-options .box-1 {
    gap: 12px;
  }
}

@media only screen and (max-width: 750px) {
  .dashboard-Drawer-container .profile-left {
    width: 50%;
  }
}

@media only screen and (max-width: 450px) {
  .dashboard-Drawer-container .profile-left {
    width: 60%;
  }
}
