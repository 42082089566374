.applicants-wrapper {
  margin-top: 1rem;
}

.applicants-wrapper .applicants-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.applicants-wrapper .applicants-head h4 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.applicants-wrapper .applicants-head .applicants-filter-box {
  display: flex;
  gap: 20px;
  align-items: center;
}

.applicants-wrapper .applicants-head .applicants-filter-box span {
  background-color: #e5f3ff;
  display: flex;
  height: 40px;
  padding: 0 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  cursor: pointer;
  color: #245293;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
}

.applicants-wrapper .applicants-head .applicants-filter-box .search-box {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 100px;
  padding: 0 1rem;
  background: #f5f7fa;
  height: 40px;
  font-size: 18px;
}

.applicants-wrapper .applicants-head .applicants-filter-box .search-box input {
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 250px;
}

@media only screen and (max-width: 850px) {
  .applicants-wrapper {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .applicants-wrapper .applicants-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .applicants-wrapper .applicants-head .applicants-filter-box {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 500px) {
  .applicants-wrapper .applicants-head .applicants-filter-box {
    gap: 10px;
  }

  .applicants-wrapper .applicants-head .applicants-filter-box span {
    height: 38px;
    padding: 0 20px;
  }

  .applicants-wrapper .applicants-head .applicants-filter-box .search-box {
    height: 38px;
  }
}
