
.approval-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
}

.approval-modal-content .img-box {
    width: 50px;
    height: 50px;
}

.approval-modal-content .img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.approval-modal-content .close-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 2px;
    border-radius: 10px;
    background-color: rgb(214, 214, 214);
}

.approval-modal-content .close-btn:hover {
    background-color: red;
    color: #fff;
}



/* rejection modal */

.rejection-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
}

.rejection-modal-content .rejection-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.rejection-modal-content .rejection-text .close-btn{
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 2px;
    border-radius: 10px;
    background-color: rgb(233, 233, 233);
}

.rejection-modal-content .rejection-text .close-btn:hover {
    background-color: red;
    color: #fff;   
}

.rejection-modal-content .msg-box {
    width: 100%;
}

.rejection-modal-content .msg-box textarea{
    width: 100%;
    border-radius: 10px;
    padding: 1rem;
    color: rgb(212, 212, 212);
}

.rejection-modal-content .msg-box .btn {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    outline: navajowhite;
    border: none;
}

.rejection-modal-content .msg-box .btn.send {
    background-color: #245293;
    color: #fff;
    cursor: pointer;
}

.rejection-modal-content .msg-box .btn.cancel {
    color: #245293;
    font-weight: 600;
}
/* rejection modal */


/* documents modal */
.document-modal-content{
    display: flex;
    flex-direction: column;
}

.document-modal-content .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
}

.document-modal-content .title-box .close-btn {
    font-size: 37px;
    cursor: pointer;
}

.document-modal-content .title-box .close-btn:hover {
    background-color: red;
    color: #fff;   
}
.document-modal-content .title-box p {
    font-size: 24px;
    font-weight: 700;
}
.document-modal-content .cv-box {
    height: 570px;
}

.document-modal-content .cv-box img {
    width: 100%;
    height: 100%;
}

.document-modal-content .approve-btn-box {
     margin-top: 0.7rem;
     display: flex;
     justify-content: space-between;
     align-items: center;
}

.document-modal-content .approve-btn-box button {
    padding: 12px 20px;
    border-radius: 15px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.document-modal-content .approve-btn-box button.reject {
    background: #DB3E4D;
}

.document-modal-content .approve-btn-box button.shortlist {
    background: #3A649E;
}