.applicants-page-container .applicants-page-right {
  width: 100%;
  padding: 0.2rem 2rem;
  margin-top: 1.5rem;
}

.applicants-page-container .applicants-page-right .applicants-page-results {
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .loading-state
  p {
  font-size: 1rem;
  font-weight: 600;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .applicants-box-result {
  border-radius: 8px;
  background: #f5f7fa;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.5rem;
  width: 100%;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .applicants-box-result
  .check {
  width: 5%;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .applicants-box-result
  .dot-bg {
  width: 7%;
}

.applicants-page-container
  .applicants-page-right
  .applicants-page-results
  .applicants-box-result
  h5 {
  color: #1c2533;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  width: 22%;
}

@media only screen and (max-width: 1000px) {
  .applicants-page-container .applicants-page-right .applicants-page-results {
    padding: 1rem 2rem;
    margin: 4rem 0 2rem;
  }
}

@media only screen and (max-width: 850px) {
  .applicants-page-container
    .applicants-page-right
    .applicants-page-results
    .applicants-box-result {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .applicants-page-container .applicants-page-right {
    width: 100%;
    padding: 0.2rem 1rem;
    margin-top: 1.5rem;
  }

  .applicants-page-container .applicants-page-right .applicants-page-results {
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 2rem;
  }
}
