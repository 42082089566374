.modal-container {
    border-radius: 24px;
}

.MuiDialog-container .MuiDialog-paper {
    border-radius: 12px;
    width: 1200px;
}

@media only screen and (max-width: 475px) {
    .MuiDialog-container .MuiDialog-paper {
        border-radius: 10px;
        width: 100%;
        margin: 5px;
    }
}

