.admin-info-box-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 1.2rem 0 2rem;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.admin-info-box-2 .admin-info-right {
  width: 23%;
  height: 120px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 1rem;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
}

.admin-info-box-2 .admin-info-right span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.admin-info-box-2 .admin-info-right h4 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.admin-info-box-2 .admin-info-right .link {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.32px;
  text-decoration: underline;
  color: #fff;
}