.backend-applicants-tabs {
  margin-top: 0.5rem;
  padding: 0.2rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.backend-applicants-tabs span {
  margin-right: 15px;
  background-color: #f1f1f1;
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.16px;
  padding: 0.2rem 0.8rem;
  border-radius: 15px;
  cursor: pointer;
}

.backend-applicants-tabs span.active {
  background-color: #18a0fb !important;
  color: #fff;
}
