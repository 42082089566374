.job-activity-con .job-activity-board {
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-radius: 20px;
  margin: 2rem 2rem 0;
}

.job-activity-con .job-activity-board .jobs-tabs {
  margin-top: 0.5rem;
  padding: 0.2rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.job-activity-con .job-activity-board .jobs-tabs span {
  margin-right: 15px;
  background-color: #f1f1f1;
  color: #606060;
  font-size: 16px;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: -0.16px;
  padding: 0.2rem 0.8rem;
  border-radius: 15px;
  cursor: pointer;
}

.job-activity-con .job-activity-board .jobs-tabs span.active {
  background-color: #18a0fb !important;
  color: #fff;
}

.job-activity-con .job-activity-board .shortlist-right-content {
  padding: 1rem 0;
}

.job-activity-con
  .job-activity-board
  .shortlist-right-content
  .job-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.job-activity-con
  .job-activity-board
  .shortlist-right-content
  .job-list-container
  .shortlist-jobItem {
  width: 30%;
  cursor: pointer;
}
