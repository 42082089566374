.menu {
    padding-right: 20px;
    display: flex;
    align-items: flex-end;
    margin: auto;
    justify-content: flex-end;
}

.menu .widget-box {
    display: flex;
    align-items: center;
    gap: 15px;
}

.menu .widget-box h4 {
    text-transform: uppercase;
}

.menu .widget-box .bag {
    background-color: #EBF0F8;
    padding: 8px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
}

.menu .widget-box .bag span {
    font-size: 12px;
    font-weight: 700;
}