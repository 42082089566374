.login-container {
    display: flex;
    flex-direction: row;
}

/* left side */
/* left side */
.login-left {
    position: fixed;
    width: 100%;
    height: 100%;
    width: 40%;
    max-height: 100vh;
    overflow: hidden;
}

.login-container .login-left .login-left-content {
    background: url(../../../assets/bg-g.png);
    padding: 0.4rem 1.5rem 2rem 5rem;
    height: 100vh;
}

.login-container .login-left .logo {
    height: 60px;
    width: 60px;
    margin: 0.3rem 0 2rem 0;
    cursor: pointer;
}

.login-container .login-left .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-container .login-left .login-left-text h3 {
    font-size: 30px;
    color: #fff;
    width: 70%;
    margin: 0 auto;
    margin: 1rem 0;
    font-weight: 800;
    line-height: 40px;
}

.login-container .login-left .login-left-text p {
    font-size: 16px;
    color: #fff;
    width: 70%;
    margin: 0 auto;
    margin: 0.4rem 0;
    margin-bottom: 0rem;
}

.login-container .login-left .login-left-image {
    position: relative;
}

.login-container .login-left .login-left-image img {
    width: fit;
    height: fit;
    object-fit: contain;
}
/* left side */
/* left side */



/* right side */
/* right side */
.login-container .login-right {
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0 5rem;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container .login-right .login-right-body {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.login-container .login-right .login-button-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: auto;
}

.login-container .login-right .login-button-box img {
    display: none;
}

.login-container .login-right .login-button-box .login-btn-right-btn {
    width: 100px;
    padding: 0.2rem;
    border-radius: 20px;
    border: none;
    outline: none;
    background-color: #245293;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    font-family: 600;
}

.login-container .login-right .login-right-content {
    margin-top: 3.5rem;
    width: 80%;
    padding: 2rem 0 2rem;
}

.login-container .login-right .login-right-content .form-label {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 1.5rem;
}

.login-container .login-right .login-right-content form .orbg {
    margin: 2rem 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    
}

.login-container .login-right .login-right-content form .orbg .border {
    height: 1px;
    background-color: #828282;
    width: 50%;
}

.login-container .login-right .login-right-content form .orbg h5 {
    font-size: 16px;
    color: #828282;
    line-height: 24px;
    font-weight: 400;
}

.login-container .login-right .login-right-content form .forgot-password {
    text-align: right;
    font-size: 16px;
    color: #20A4D3;
    cursor: pointer;
    font-weight: 400;
    line-height: 32px;
    margin-top: 1rem;
}

.login-container .login-right .login-right-content form .login-btn {
    width: 100%;
    border-radius: 30px;
    border: none;
    outline: none;
    background-color: #245293;
    color: #fff;
    cursor: pointer;
    margin-top: 1rem;
    height: 45px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 2rem;
}

.login-container .login-right .login-right-content form .or {
    font-size: 16px;
    text-align: center;
    margin: 1rem 0;
    color: #828282;
}

.login-container .login-right .login-right-content form .account {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 2rem 0;
    text-align: center;
}

.login-container .login-right .login-right-content form h5 {
    font-size: 16;
    line-height: 32;
}

.login-container .login-right .login-right-content form .account .join {
    color: #20388A;
    cursor: pointer;
}
/* right side */
/* right side */

@media only screen and (max-width: 1500px) {
    .login-container .login-left .login-left-content {
        padding: 0.4rem 1rem 2rem 2.5rem;
    }

    .login-container .login-left .login-left-text h3 {
        font-size: 30px;
        color: #fff;
        width: 80%;
        margin: 0 auto;
        margin: 0.5rem 0;
    }

    .login-container .login-left .login-left-image img {
    
        height: 450px;
        object-fit: contain;
    
    }
}

@media only screen and (max-width: 1200px) {
    .login-container .login-right {
        position: static;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0 5rem;
        background-color: #fff;
    }

    .login-container .login-right .login-right-body {
        width: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .login-container {
        flex-direction: column;
    }

    .login-container .login-left {
        display: none;
    }

    .login-container .login-right .login-right-content {
        width: 100%;
        padding: 1.5rem 0.5rem;
        margin-top: 3rem;

    }

    .login-container .login-right .login-button-box {
        width: 100%;
        padding:0;
        width: 98%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.2rem;
    }
    
    .login-container .login-right .login-button-box img {
        display: block;
    }
}

@media only screen and (max-width: 800px) {
    .login-container .login-right .login-right-body {
        width: 95%;
    }
}