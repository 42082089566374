.mobileNavWrapper {
    display: none;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 9999;
    margin-top: 0.5rem;
}

.height-bg-showNavDrawer {
    height: 100%;
}

.navbar-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.dashboardNavbarMobile-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    background-color: #fff;
    margin-bottom: 0.5rem;
}

.dashboardNavbarMobile-container .logo-img {
    width: 50px;
    height: 32px;
    object-fit: contain;
}

.dashboardNavbarMobile-container .logo-img img {
    width: 100%;
    height: 100%;
}

.dashboardNavbarMobile-container .menu {
    width: 100%;
}

.dashboardNavbarMobile-container .menu .right-nav {
    display: flex;
    align-items: center;
}

.dashboardNavbarMobile-container .menu .bag {
    cursor: pointer;
    font-size: 25px;
    padding-top: 2px;
}

.dashboardNavbarMobile-container .menu .cancel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E3EEF8;
    border-radius: 20px;
    height: 35px;
    width: 35px;
}

.dashboardNavbarMobile-container .menu .widget-box .bag span {
    font-size: 14px;
    font-weight: 700;
}


@media only screen and (max-width: 1000px) {
    .mobileNavWrapper {
        display: block;
    }

    .mobileNavWrapper .nav-nav-box {
        margin-right: 1rem;
    }
}