.singleApplicantPageContainer {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem;
}

.singleApplicantPageContainer .single-applicant-page-content {
  padding: 2rem;
  border-radius: 24px;
  background: #fff;
  width: 100%;
}

.singleApplicantPageContainer .single-applicant-page-content .page-content-top {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1rem 0;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image {
  border-radius: 50px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-image
  img {
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name {
  display: flex;
  gap: 7px;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.verified {
  color: #245293;
  background: #eef7ff;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-name
  .status.pending {
  background: #fef7e4;
  color: #f5a656;
  padding: 2px 6px;
  border-radius: 9px;
  font-size: 15px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  .dot {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-email
  p {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons {
  display: flex;
  gap: 17px;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .disable-btn {
  background-color: rgb(187, 184, 184);
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn {
  padding: 14px 32px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 100px;
  opacity: 0.6;
  width: 120px;
  cursor: pointer;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.block {
  color: #db3e4d;
  background: #f9e3e0;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.reject {
  background: #e5f3ff;
  color: #245293;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-top
  .top-buttons
  .btn.approve {
  background: #245293;
  color: #fff;
  font-weight: 700;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head
  p {
  color: #808080;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.28px;
  padding: 0 15px 10px;
  width: fit-content;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head
  .active-tab {
  color: #245293;
  border-bottom: 2px solid #245293;
}

.singleApplicantPageContainer
  .single-applicant-page-content
  .page-content-bottom
  .bottom-head
  hr {
  margin-top: 1rem;
  background-color: #d8d8d8;
  color: #d8d8d8;
}

.singleApplicantPageContainer .page-content-bottom .bottom-boxes {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.singleApplicantPageContainer .page-content-bottom .bottom-boxes .box-content {
  width: 350px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 30px 20px;
}

.singleApplicantPageContainer
  .page-content-bottom
  .bottom-boxes
  .box-content
  .grad-icon {
  font-size: 28px;
  margin-bottom: 0.5rem;
  font-weight: 300;
}

.singleApplicantPageContainer
  .page-content-bottom
  .bottom-boxes
  .box-content
  .title {
  font-size: 18px;
  margin-bottom: 0.2rem;
  font-weight: 600;
}

.singleApplicantPageContainer
  .page-content-bottom
  .bottom-boxes
  .box-content
  .small-title {
  font-size: 17px;
  color: #808080;
  margin-bottom: 0.9rem;
}

.singleApplicantPageContainer
  .page-content-bottom
  .bottom-boxes
  .box-content
  .btn-box {
  display: flex;
  justify-content: flex-end;
}

.singleApplicantPageContainer
  .page-content-bottom
  .bottom-boxes
  .box-content
  .btn {
  border: none;
  color: #245293;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  padding: 0.3rem 0.7rem;
  border-radius: 9px;
}

@media only screen and (max-width: 1000px) {
  .singleApplicantPageContainer {
    min-height: 100vh;
    margin: 1rem 1rem 1rem;
  }
}

@media only screen and (max-width: 850px) {
  .singleApplicantPageContainer {
    min-height: 100vh;
    margin: 5rem 1rem 1rem;
  }

  .singleApplicantPageContainer
    .single-applicant-page-content
    .page-content-top
    .top-buttons {
    display: flex;
    gap: 17px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 500px) {
  .singleApplicantPageContainer .single-applicant-page-content {
    padding: 2rem 1rem;
    border-radius: 18px;
  }

  .singleApplicantPageContainer
    .page-content-bottom
    .bottom-boxes
    .box-content {
    width: 420px;
    border-radius: 10px;
    padding: 30px 15px;
  }
}
